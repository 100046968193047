import React, { useState, useEffect } from 'react';
import css from './LocationFilter.module.css';
import { LocationForm } from './LocationForm';
import { useConfiguration } from '../../context/configurationContext';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { parse } from '../../util/urlHelpers';
import { useIntl } from 'react-intl';
import PopupOpenerButton from '../../containers/SearchPage/PopupOpenerButton/PopupOpenerButton';

import Modal from '../Modal/Modal';
import { types } from '../../util/sdkLoader';
const { LatLng } = types;
// const onManageDisableScrolling = (componentId, scrollingDisabled = true) => {
//   // We are just checking the value for now
//   console.log('Toggling Modal - scrollingDisabled currently:', componentId, scrollingDisabled);
// };
const LocationFilter = ({ isOpenLocationFilter, handleLocation, onManageDisableScrolling }) => {
  const config = useConfiguration();
  const history = useHistory();
  const location = history?.location;
  const intl = useIntl();
  const [showModal, setShowModal] = useState(false);
  const { address, origin, bounds, mapSearch, radius, ...rest } = parse(location.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  const getTitle = title => (title.length > 25 ? title.substring(0, 23) + '...' : title);
  const defaultOrigin = new LatLng(40.421345, -3.701219);
  const isMobileView = typeof window !== 'undefined' && window.innerWidth < 767;
  const defaultAddress = address || ' Madrid';
  const topbarSearcInitialValues = () => {
    return {
      radius: radius || 10,
      location: {
        search: address || defaultAddress,
        selectedPlace: {
          address: address || defaultAddress,
          origin: origin || defaultOrigin,
          bounds,
        },
      },
    };
  };

  const toggleCard = () => {
    handleLocation(!isOpenLocationFilter);
    setShowModal(!showModal);
  };
  return (
    <div
      className={css.container}
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <PopupOpenerButton isSelected={defaultAddress} toggleOpen={toggleCard}>
        <span className={css.mobileLabel}>
          {getTitle(radius ? `${radius} km - ${address}` : `10km - ${defaultAddress}`)}
        </span>
        <span className={css.desktopLabel}>
          {radius ? `${radius} km - ${address}` : `10km - ${defaultAddress}`}
        </span>
      </PopupOpenerButton>

      <div className={isOpenLocationFilter ? css.priceCardOpen : css.priceCard}>
        <div className={css.header}>
          {/* <h3 className={css.title}>
            {intl.formatMessage({ id: 'LocationFilter.LocationFilter.filterByLocation' })}
          </h3> */}
        </div>
        <div className={css.content}>
          <LocationForm
            intl={intl}
            initialValues={topbarSearcInitialValues()}
            onSubmit={values => console.log('onSubmit:', values)}
            config={config}
            history={history}
            toggleCard={toggleCard}
            rest={rest}
            isMobileView={isMobileView}
          />
        </div>
      </div>
      {isMobileView && showModal ? (
        <div className={css.modal}>
          <Modal
            isOpen={isOpenLocationFilter}
            containerClassName={css.modalContainer}
            onClose={() => {
              handleLocation(false);
            }}
            // usePortal
            location={true}
            hideClose={true}
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <div className={css.modalContent}>
              <div className={css.header}>
                {/* <h3 className={css.title}>
                  {intl.formatMessage({ id: 'LocationFilter.LocationFilter.filterByLocation' })}
                </h3> */}
              </div>
              <div className={css.content}>
                <LocationForm
                  intl={intl}
                  initialValues={topbarSearcInitialValues()}
                  onSubmit={values => console.log('onSubmit:', values)}
                  config={config}
                  history={history}
                  toggleCard={toggleCard}
                  rest={rest}
                  isMobileView={isMobileView}
                />
              </div>
            </div>
          </Modal>
        </div>
      ) : null}
    </div>
  );
};

export default LocationFilter;
