import React, { useState, useEffect, useRef } from 'react';
import Map, { Marker, Source, Layer } from 'react-map-gl';
import mapboxgl, { LngLatBounds } from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import * as turf from '@turf/turf';
import css from './LocationFilter.module.css';
import { FaMapMarkerAlt } from 'react-icons/fa';
import ReactSlider from 'react-slider';
import Slider from 'react-rangeslider';
// import 'react-rangeslider/lib/index.css';
const MapComponent = props => {
  const {
    mapBoxAccessToken,
    geolocation,
    boundingBox,
    handleBoundingBox,
    radius,
    handleRadius,
    intl,
    isMobileView,
    handleReset,
    reset,
    handleOrigin,
  } = props;
  mapboxgl.accessToken = mapBoxAccessToken;
  mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
  const [resetTrigger, setResetTrigger] = useState(false);
  const [sliderValue, setSliderValue] = useState(radius);
  useEffect(() => {
    if (radius < 20) {
      setSliderValue(radius);
    }
    if (radius == 30) {
      setSliderValue(21);
    }
    if (radius == 40) {
      setSliderValue(22);
    }
    if (radius == 50) {
      setSliderValue(23);
    }
  }, []);
  const [viewport, setViewport] = useState({
    latitude: geolocation?.lat,
    longitude: geolocation?.lng,
    zoom: 9,
  });
  // Function to handle reset
  const onReset = shouldReset => {
    handleReset(shouldReset);
    setResetTrigger(prev => !prev); // Toggle resetTrigger to force rerender
  };
  const [center, setCenter] = useState([viewport.longitude, viewport.latitude]);
  const [circleData, setCircleData] = useState(null);
  const mapRef = React.useRef(null);
  const calculateZoomLevel = radius => {
    const maxZoom = radius < 15 ? 10 : 9;
    const minZoom = 5;
    const maxRadius = 200;
    if (radius == 1) {
      return 13;
    }
    if (radius < 4) {
      return 12;
    }
    if (radius < 6) {
      return 11;
    }

    // const newZoom = maxZoom - (radius / maxRadius) * (maxZoom - minZoom);
    const newZoom = maxZoom - (radius / maxRadius) * (maxZoom - minZoom);

    return Math.max(minZoom, Math.min(newZoom, maxZoom));
    return newZoom;
  };

  const updateCircleAndBoundingBox = (center, radius) => {
    const circle = turf.circle(center, radius, {
      steps: 64,
      units: 'kilometers',
    });
    const bbox = turf.bbox(circle);
    setCircleData(circle);
    handleBoundingBox(bbox);
  };
  useEffect(() => {
    if (geolocation) {
      const { lat, lng } = geolocation;
      setCenter([lng, lat]);
      handleOrigin([lng, lat]); // Set the origin of the pin
      setViewport(prev => ({
        ...prev,
        latitude: lat,
        longitude: lng,
      }));
    }
  }, [geolocation]);
  useEffect(() => {
    updateCircleAndBoundingBox(center, radius);

    // Update zoom level based on radius
    const newZoom = calculateZoomLevel(radius);

    setViewport(prev => ({ ...prev, zoom: newZoom }));
  }, [center, radius]);

  useEffect(() => {
    if (mapRef.current) {
      const geocoder = new MapboxGeocoder({
        accessToken: mapBoxAccessToken,
        mapboxgl: mapboxgl,
        marker: false,
      });

      geocoder.on('result', e => {
        setCenter([e.result.center[0], e.result.center[1]]);
      });
      onReset(true);
    }
  }, [resetTrigger]);

  const layerStyle = {
    id: 'circle-fill',
    type: 'fill',
    paint: {
      'fill-color': '#8768df',
      'fill-opacity': 0.3,
    },
  };
  // Define the bounding box for Spain
  const spainBounds = new LngLatBounds(
    [-9.392883, 35.94685], // Southwest corner (longitude, latitude)
    [3.039484, 43.748337] // Northeast corner (longitude, latitude)
  );
  const outlineStyle = {
    id: 'circle-outline',
    type: 'line',
    paint: {
      'line-color': '#5532ba',
      'line-width': 2,
    },
  };

  return (
    <div>
      <div
        className={isMobileView ? css.mapbox : css.mapboxDesktop}
        style={{ width: '100%', height: isMobileView ? '400px' : '300px' }}
      >
        <Map
          style={{ width: '100%', height: isMobileView ? '400px' : '300px' }}
          ref={mapRef}
          {...viewport}
          onMove={evt => setViewport(evt.viewState)}
          mapStyle="mapbox://styles/mapbox/streets-v10"
          onClick={e => setCenter([e.lngLat.lng, e.lngLat.lat])}
          onRender={event => event.target.resize()}
          dragPan={true}
          scrollZoom={true}
          maxBounds={spainBounds}
          touchZoomRotate={{
            disableRotation: true, // Disables rotation gestures
            enabled: false, // Disables pinch zoom gestures
          }}
        >
          <Marker
            longitude={center[0]}
            latitude={center[1]}
            draggable={false}
            onDragEnd={e => setCenter([e.lngLat.lng, e.lngLat.lat])}
          >
            <FaMapMarkerAlt className={css.mapMarker} />
          </Marker>
          {circleData && (
            <Source id="circle-data" type="geojson" data={circleData}>
              <Layer {...layerStyle} />
              <Layer {...outlineStyle} />
            </Source>
          )}
        </Map>
      </div>

      <label>
        <div className={css.sliderContainer}>
          <p className={css.sliderLabel}>{radius} km</p>
          <p className={css.sliderLabel}>50 km</p>
        </div>
        <div className={css.div}>
          <ReactSlider
            className={css.horizontalslider}
            // marks
            markClassName={css.mark}
            min={1}
            max={23}
            thumbClassName={css.thumb}
            trackClassName={css.track}
            value={sliderValue}
            onChange={value => {
              let newValue = value;
              if (value < 20) {
                newValue = value;
              }
              if (value == 21) {
                newValue = 30;
              }
              if (value == 22) {
                newValue = 40;
              }
              if (value == 23) {
                newValue = 50;
              }
              setSliderValue(value);
              handleRadius(newValue);
              setViewport(prev => ({
                ...prev,
                latitude: center[1], // Pin's latitude
                longitude: center[0], // Pin's longitude
              }));
            }}
            renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
          />
        </div>
        {/*         
        <Slider
          min={1}
          max={50} // Keep max consistent at 100 for simplicity
          step={23} // Keep step at 1 to avoid re-renders due to step change
          value={radius}
          onChange={value => {
            const newValue = value >= 20 ? Math.round(value / 10) * 10 : value;
            handleRadius(newValue);
            setViewport(prev => ({
              ...prev,
              latitude: center[1], // Pin's latitude
              longitude: center[0], // Pin's longitude
            }));
          }}
          tooltip={false}
        /> */}
      </label>
    </div>
  );
};

export default MapComponent;
