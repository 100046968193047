import React, { useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import Form from '../Form/Form';
import css from './LocationFilter.module.css';
import { FieldLocationAutocompleteInput } from '../LocationAutocompleteInput/LocationAutocompleteInput';
import MapComponent from './MapComponent';
import { types as sdkTypes } from '../../util/sdkLoader';
import { isOriginInUse } from '../../util/search';
import { createResourceLocatorString } from '../../util/routes';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { HiArrowNarrowLeft } from 'react-icons/hi';
import { ImCross } from 'react-icons/im';
import { TiDelete } from 'react-icons/ti';

const { LatLng, LatLngBounds } = sdkTypes;
const identity = v => v;
export const LocationForm = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        handleSubmit,
        config,
        values,
        history,
        toggleCard,
        intl,
        rest,
        form,
        isMobileView,
      } = formRenderProps;

      if (typeof window === 'undefined') return null;
      const [boundingBox, setBoundingBox] = useState(null);
      const [pinOrigin, setPinOrigin] = useState([]);
      const [radius, setRadius] = useState(values?.radius || 0.5);
      const [reset, setReset] = useState(false);
      const handleReset = () => {
        setReset(true);
      };
      const handleRadius = radius => {
        setRadius(radius);
      };
      const handleBoundingBox = boundingBox => {
        setBoundingBox(boundingBox);
      };
      const handleOrigin = origin => {
        setPinOrigin(origin);
      };
      const routeConfiguration = useRouteConfiguration();
      const mapsConfig = config.maps;
      const mapBoxAccessToken = mapsConfig?.mapboxAccessToken;

      const geolocation = values?.location?.selectedPlace?.origin;

      const handleLocation = () => {
        if (geolocation && boundingBox) {
          const { search, selectedPlace } = values?.location;
          const { addressForLabel, addressLabelForMobile } = selectedPlace;
          if (typeof window == 'undefined') return;
          const isMobileView = window.innerWidth < 768;

          const bounds = new LatLngBounds(
            new LatLng(boundingBox[3], boundingBox[2]),
            new LatLng(boundingBox[1], boundingBox[0])
          );
          const pinOriginData = new LatLng(pinOrigin[1], pinOrigin[0]);
          const { origin } = selectedPlace;

          const originMaybe = pinOriginData ? { origin: pinOriginData } : { origin };

          history.push(
            createResourceLocatorString(
              'SearchPage',
              routeConfiguration,
              {},
              {
                ...rest,
                ...originMaybe,
                address: isMobileView
                  ? addressLabelForMobile || addressForLabel || search
                  : addressForLabel || search,
                radius,
                bounds,
              }
            )
          );
          toggleCard();
        } else {
          toggleCard();
        }
      };

      return (
        <Form onSubmit={handleSubmit} className={css.root}>
          <div className={css.locationContainer}>
            <HiArrowNarrowLeft className={css.leftArrow} onClick={() => toggleCard()} />
            <FieldLocationAutocompleteInput
              className={css.locationInput}
              rootClassName={css.locationAddress}
              inputClassName={css.locationAutocompleteInput}
              iconClassName={css.locationAutocompleteInputIcon}
              predictionsClassName={css.predictionsRoot}
              validClassName={css.validLocation}
              name="location"
              placeholder={intl.formatMessage({ id: 'LocationFilter.LocationForm.location' })}
              useDefaultPredictions={false}
              format={identity}
              valueFromForm={values.location}
              isMapFilter={true}
            />
            <div className={css.deleteIcon}>
              <TiDelete className={css.delete} onClick={() => form.change('location', null)} />
            </div>
          </div>
          {/* {values?.location && geolocation ? ( */}
          <div>
            <div className={css.map}>
              <MapComponent
                geolocation={geolocation}
                mapBoxAccessToken={mapBoxAccessToken}
                boundingBox={boundingBox}
                handleBoundingBox={handleBoundingBox}
                radius={radius}
                handleOrigin={handleOrigin}
                handleRadius={handleRadius}
                intl={intl}
                isMobileView={isMobileView}
                handleReset={handleReset}
                reset={reset}
              />
            </div>
            <div className={css.footer}>
              {/* <button
                  type="button"
                  className={css.cancelButton}
                  onClick={() => {
                    toggleCard();
                  }}
                >
                  {intl.formatMessage({ id: 'LocationFilter.LocationForm.cancel' })}
                </button>
                <button type="button" className={css.nextButton} onClick={handleLocation}>
                  {intl.formatMessage({ id: 'LocationFilter.LocationForm.next' })}
                </button> */}
              <button type="button" className={css.nextButtonMobile} onClick={handleLocation}>
                {intl.formatMessage(
                  { id: 'LocationFilter.LocationForm.mobileNext' },
                  { radius: radius }
                )}
              </button>
            </div>
          </div>
          {/* ) : null} */}
        </Form>
      );
    }}
  />
);
